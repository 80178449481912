import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import OnboardingForm from "./components/OnBoardingForm"; 
import SuccessMessage from './components/SuccessMessage/SuccessMessage';
import GmailRedirectionPage from "./components/GmailRedirectionPage";
// import CustomTypeform from "./components/CustomTypeform";
function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<OnboardingForm />} />
      <Route path="/success" element={<SuccessMessage />} />
      <Route path="/gmail-auth" element={<GmailRedirectionPage />} />
      {/*<Route path="/typeform" element={<CustomTypeform />} />*/}
      </Routes>
    </Router>
  );
}

export default App;


