import React from 'react';
import './SuccessMessage.css';
import { useLocation } from 'react-router-dom';


const SuccessMessage = () => {
  const location = useLocation();
  const { onboardingPreference } = location.state || {};

  const handleIMRedirect = () => {
    // Redirect to iMessage with a pre-filled message
    const phoneNumber = "+16464083706";
    const message = encodeURIComponent("Hi!");
    window.location.href = `sms:${phoneNumber}&body=${message}`;
  };

  const handleWhatsAppAppRedirect = () => {
    // Redirect to WhatsApp app
    window.location.href = "https://wa.me/918951089503/";
  };

  const handleWhatsAppWebRedirect = () => {
    // Open WhatsApp Web in a new tab
    window.open("https://web.whatsapp.com", "_blank");
  };


  return (
    <div className="success-logo-card-container">
      <div className="success-logo-container">
        <img src="/images/ftlogo.svg" alt="FastTrackr" className="success-logo" />
      </div>
      <div className="success-card">
        <div className="success-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2l4 -4M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10 -4.48 10 -10S17.52 2 12 2z"
            />
          </svg>
        </div>
        <h2>Congratulations!</h2>
        <p className="success-text">Your EA is now ready</p>
        {onboardingPreference === "IM" ? (
          <div className="success-button-container">
        <button className="success-btn" onClick={handleIMRedirect}>
          Go to iMessage
        </button>
        </div>
      ) : (
        <div className="success-button-container">
          <button className="success-btn" onClick={handleWhatsAppAppRedirect}>
            Go to WhatsApp app
          </button>
          <button className="success-btn" onClick={handleWhatsAppWebRedirect}>
            Go to WhatsApp web
          </button>
        </div>
      )}
      </div>
    </div>
  );
};

export default SuccessMessage;
