import React, { useEffect, useState } from 'react';
import "../styles/global.css";
import Modal from "./Modal";

function GmailRedirectionPage() {
    const phoneNumber = localStorage.getItem('phone');
    const source = localStorage.getItem('source');
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState("");
    const [modalHeader, setModalHeader] = React.useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isLoading, setIsLoading] = useState(false);  // New loading state

    const handleGmailAuthRedirect = () => {
        if (phoneNumber && source) {
            setIsLoading(true);  // Set loading to true when the button is clicked
            window.location.href = `https://emailauth.fasttrackr.ai/start?phone=${phoneNumber}&source=${source}`;
        } else {
            console.error('Missing phone number or source in localStorage');
            setIsModalOpen(true);
            setModalMessage("Something went wrong!");
            setModalHeader("Error!");
        }
    };

    const handleWhyThisClick = () => {
        setModalMessage(
            "FastTrackr AI requires this access to draft emails, schedule meetings, and create scheduling links for you. Rest assured, we are CASA Tier 2 certified and Google verified."
        );
        setModalHeader("Why we need this access?");
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: isMobile ? '10px' : '0px',
            flexDirection: 'column',
        },
        overlay: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: isMobile ? '90%' : '70%',
            maxWidth: '900px',
            height: isMobile ? 'auto' : '380px',
            backgroundColor: 'white',
            borderRadius: '20px',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.15)',
            padding: '20px',
            flexDirection: isMobile ? 'column' : 'row',
            marginTop: '10px',
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            textAlign: 'center',
            marginBottom: isMobile ? '20px' : '0',
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            textAlign: 'center',
        },
        verticalLine: {
            height: '70%',
            borderLeft: '1.6px solid #ddd',
            margin: '0 20px',
            display: isMobile ? 'none' : 'block',
        },
        logo: {
            width: isMobile ? '180px' : '275px',
            height: 'auto',
            margin: '20px',
            marginTop: '-1px',
            objectFit: 'contain',
            backgroundColor: '#f3efe7', // Light grey background for subtle effect
            padding: '10px',            // Adds space around the logo
            borderRadius: '10px',       // Rounds the edges slightly
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow for a lifted look
        },

        text: {
            color: '#4d4d4d',
            fontSize: isMobile ? '14px' : '16px',
            fontWeight: '400',
            margin: '20px',
            lineHeight: '1.6',
        },
        // textPopup: {
        //     color: '#1a73e8',
        //     fontSize: '14px',
        //     fontWeight: '200',
        //     marginBottom: '10px',
        //     cursor: 'pointer',
        //     textAlign: 'left',
        //     alignSelf: 'flex-start',
        //     paddingLeft: '5px',
        //     marginLeft: '65px',
        //     display: 'inline-block',
        //     transition: 'color 0.3s ease, box-shadow 0.3s ease', // Added transition for smooth hover effect
        // },
        // button: {
        //     backgroundColor: '#ffffff',
        //     borderRadius: '20px',
        //     color: 'black',
        //     fontSize: isMobile ? '14px' : '16px',
        //     padding: '12px 10px',
        //     border: '1px solid black',
        //     cursor: 'pointer',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     width: '70%',
        //     margin: '20px 0',
        //     transition: 'all 0.3s ease',
        //     boxShadow: '0 0 5px rgba(0, 0, 0.1, 0.5)',
        // },
        googleIcon: {
            width: '24px',
            height: '24px',
            marginRight: '15px',
        },
        // spinner: {
        //     width: '24px',
        //     height: '24px',
        //     border: '3px solid #f3f3f3',
        //     borderTop: '3px solid #3498db',
        //     borderRadius: '50%',
        //     animation: 'spin 1s linear infinite',
        // },
        topText: {
            fontSize: '24px',
            fontWeight: '600',
            color: '#333',
            margin: '25px 0',
            textAlign: 'center',
            lineHeight: '1.5',
        },
    };

    return (
        <div style={styles.container}>
            <div className="logo-container">
                <img src="/images/ftlogo.svg" alt="FastTrackr Logo" className="logo" />
            </div>

            <div style={styles.topText}>

                Just one more step to start using your AI Assistant.
            </div>

            <div style={styles.overlay}>
                <div style={styles.leftContainer}>
                    <div style={{...styles.text, fontWeight: 'bold', color: '#404040', marginBottom:'20px'}}>
                        Effortlessly manage your emails and calendar <br/> directly from WhatsApp and
                        no app to download!
                    </div>
                    <img src="/images/trans_logo_mail_whts.png" alt="FastTrackr Logo" style={styles.logo} />
                </div>

                <div style={styles.verticalLine}></div>

                <div style={styles.rightContainer}>
                    <div style={{...styles.text, fontWeight: 'bold', color: '#404040'}}>
                        <span style={{fontSize: '16px'}}>
                            Connect your Google account to get started!
                        </span>
                    </div>
                    <button
                        className="google-btn"
                        onClick={handleGmailAuthRedirect}
                        disabled={isLoading} // Disable the button when loading
                    >
                        {isLoading ? (
                            <div style={styles.spinner} className='spinner'></div> // Show spinner when loading
                        ) : (
                            <>
                                <img
                                    src="https://www.svgrepo.com/show/475656/google-color.svg"
                                    alt="Google logo"
                                    style={styles.googleIcon}
                                />

                                Connect with Google
                            </>
                        )}
                    </button>
                    <a href="#" className="textPopup" onClick={handleWhyThisClick}>
                        Why is this required?
                    </a>
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                message={modalMessage}
                heading={modalHeader}
            />
        </div>
    );
}

export default GmailRedirectionPage;
