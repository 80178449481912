import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   apiKey: "AIzaSyApSNbP6t-MbotAXuRe5Um8S1ausOZYz6k",
// authDomain: "medamax-68958.firebaseapp.com",
// projectId: "medamax-68958",
// storageBucket: "medamax-68958.appspot.com",
// messagingSenderId: "9107551634",
// appId: "1:9107551634:web:eae09c4732fc272f913d4b",
// measurementId: "G-QJFGYG9Z9E"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };